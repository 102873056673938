import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { themeCssVar as globalTheme } from '@tinderapp/ui-react';

import { DEFAULT_LOCALE, getLocale, getMessages } from '../data/messages';

import { SPACING_VERTICAL, SPACING_HORIZONTAL, BORDER_RADIUS } from '../lib/sizing';
import { FONT_WEIGHT_BOLD } from '../lib/typography';
import {
  articleDeck,
  articleHeader,
  articleHeadline,
  articleSubheader,
  maxWidthLarge,
  proximaNovaCondensedBold,
} from '../lib/styles';

import { RTL_LOCALES } from '../constants';

import { LocalizationProvider } from '../contexts/LocalizationContext';
import Layout from '../components/SafetyCenterLayout';
import SEO from '../components/Seo';
import LocalizedLinkContainer from '../components/LocalizedLinkContainer';
import Tag from '../components/PageTag';

import TextContent from '../components/TextContent';
import formatRichtext from '../lib/formatRichtext';

const ArticleHeader = styled.header`
  margin-bottom: ${SPACING_VERTICAL};
`;

const ArticleBody = styled(LocalizedLinkContainer)`
  ${TextContent} {
    padding-bottom: ${SPACING_VERTICAL(4)};

    h1,
    .headline {
      ${articleHeadline}
      color: ${globalTheme.color.textPrimary};
    }

    .deck {
      ${articleDeck}
      color: ${globalTheme.color.textInactive};

      @media ${maxWidthLarge} {
        + hr {
          margin-right: -${SPACING_HORIZONTAL};
        }
      }
    }

    .cta {
      display: inline-block;
      padding: ${SPACING_VERTICAL} ${SPACING_HORIZONTAL};
      border-radius: ${BORDER_RADIUS};
      color: ${globalTheme.color.white};
      background-color: ${globalTheme.color.gray95};

      ${proximaNovaCondensedBold}
      font-size: 1.1rem;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
    }

    p > .cta {
      display: block;
      margin-top: 1.8rem;
      margin-bottom: 1.8rem;
    }

    h2 {
      ${articleHeader}
    }

    h3 {
      ${articleSubheader}
    }

    li {
      &:not(:first-child) {
        margin-top: 0.8rem;
      }

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      /* weird selector behavior to handle nested lists */
      > p:first-child {
        margin-bottom: 0.8rem;

        + ul,
        + ol {
          margin-top: 0.8rem;
        }
      }
    }

    ol {
      list-style-type: none;
      counter-reset: numbered-list;

      li {
        /* bullet width plus the distance from the bullet to the text */
        margin-${(props) => (RTL_LOCALES.has(props.locale) ? 'right' : 'left')}: 1.8rem;

        > p {
          display: inline-flex;
          margin-top: 0 !important;
          width: 98%;
        }

        &::before {
          counter-increment: numbered-list;
          content: counter(numbered-list);

          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 1.1rem;
          height: 1.1rem;
          /* right margin: distance from the bullet to the text */
          /* left margin: negative of list item margin */
          margin-top: 0.14rem;
          margin-bottom: 0.14rem;
          margin-left: ${(props) => (RTL_LOCALES.has(props.locale) ? '0.7rem' : '-1.8rem')};
          margin-right: ${(props) => (RTL_LOCALES.has(props.locale) ? '-1.8rem' : '0.7rem')};

          font-size: 0.8rem;
          line-height: 1rem;
          font-weight: ${FONT_WEIGHT_BOLD};
          text-align: center;
          vertical-align: top;

          background-color: ${globalTheme.color.blue60};
          color: ${globalTheme.color.white};
        }
      }
    }

    figure {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;

      @media ${maxWidthLarge} {
        margin-left: -${SPACING_HORIZONTAL};
        margin-right: -${SPACING_HORIZONTAL};

        > img {
          width: 100%;
        }
      }

      > img {
        display: block;
        max-width: 100%;

        &[src$='.gif'] {
          width: 100%;
        }
      }

      > figcaption {
        color: ${globalTheme.color.textInactive};
      }
    }
  }
`;

const ContentfulWebViewPage = ({
  data,
  pageContext: {
    title,
    heroImage: hero,
    tag,
    body,
    locale = DEFAULT_LOCALE,
    redirectMap = {},
    supportedLanguages = {},
  },
}) => {
  return (
    <LocalizationProvider
      locale={getLocale(locale)}
      messages={getMessages(locale)}
      localizedPaths={supportedLanguages}
      redirectMap={redirectMap}
    >
        <Layout hero={hero?.publicUrl} locale={getLocale(locale)}>
          <SEO title={title} lang={locale} />
          {tag ? (
            <ArticleHeader locale={getLocale(locale)}>
              <Tag locale={getLocale(locale)}>{tag}</Tag>
            </ArticleHeader>
          ) : null}
          <ArticleBody tagName="article" locale={getLocale(locale)}>
            <TextContent>{formatRichtext(body, { EmbeddedAssetTagName: 'figure' })}</TextContent>
          </ArticleBody>
        </Layout>
    </LocalizationProvider>
  );
};

export const query = graphql`
  query ($slug: String!, $language: String!, $region: String!) {
    contentfulPage(node_locale: { eq: $language }, slug: { eq: $slug }, region: { eq: $region }) {
      title
      slug
      region
      heroImage {
        publicUrl
      }
      tag
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            id
            file {
              url
            }
            title
            description
          }
        }
      }

      parentPage {
        title
        slug
        parentPage {
          title
          slug
          parentPage {
            title
            slug
          }
        }
      }
    }
  }
`;

export default ContentfulWebViewPage;
